<template>
  <div id="chat-screen">
    <div id="chat-head">
      <div id="iara-avatar"></div>
      <p id="title">
        {{ transferido ? "Central de Atendimento" : "IARA – AGENTE VIRTUAL" }}
      </p>
      <!--<button v-if="transferido" class="message-button" @click="transferToIara">Voltar à Iara</button>-->
      <font-awesome-icon
        :icon="['fa', 'minus']"
        class="close-chat"
        @click="closeChat"
      />
    </div>
    <div v-if="!transferido" id="chat-history">
      <div id="chat-messages">
        <message-balloon
          v-for="(message, index) in messages"
          :key="index"
          :message="message"
          @click-button="clickButton"
        />
        <typing-balloon v-if="typing" />
      </div>
    </div>
    <div v-if="!transferido" id="chat-text-box">
      <input
        type="text"
        v-model="msg"
        placeholder="Digite sua mensagem..."
        v-on:keyup.enter="sendMessage"
      />
      <button class="send-message" @click="sendMessage">
        <font-awesome-icon
          :icon="['fas', 'paper-plane']"
          class="send-message-icon"
        />
      </button>
    </div>
  <iframe
      id="chat-inventtis"
      sandbox="allow-same-origin allow-scripts allow-popups allow-modals"
      allow="microphone *;midi"
      v-if="transferido"
      :src="urlIChatInventtis"
    ></iframe>
    <audio id="chat-notification-sound" src="../../public/notification_msg.wav"></audio>
  </div>
</template>

<script>
import MessageBalloon from "./MessageBalloon.vue";
import TypingBalloon from "./TypingBalloon.vue";

export default {
  name: "ChatScreen",
  components: {
    MessageBalloon,
    TypingBalloon,
  },
  props: {
    messages: Array,
    transferido: Boolean,
    urlIChatInventtis: String,
    connected: Boolean
  },
  watch: {
    messages: {
      handler: function (newVal) {
        if (newVal[newVal.length - 1].from === "operador") {
          this.typing = false;
        } else {
          this.setTyping();
        }
      },
      deep: true,
    },
    connected: function () {
      this.setTyping();
    },
    typing: function(newVal){
      if (newVal === true && this.messages.length > 0 && this.messages[this.messages.length - 1].from === "operador"){
        this.typing = false;
      }
    }

  },
  data() {
    return {
      msg: "",
      isConnected: false,
      socketMessage: "",
      typing: false,
    };
  },
  methods: {
    setTyping() {
      setTimeout(() => {
        this.typing = true;
        setTimeout(() => {
          this.typing = false;
        }, 5000);
      }, 300);
    },
    closeChat() {
      this.$emit("close-chat");
    },
    sendMessage() {
      if (this.msg !== "") {
        this.$emit("send-message", this.msg);
        this.msg = "";
      }
    },
    clickButton(message) {
      this.msg = message;
      this.sendMessage();
    },
    transferToIara() {
      this.$emit("transfer-to-iara");
    },
  },
};
</script>

<style scoped lang="scss">
$headerHeight: 50px;

#chat-screen {
  position: absolute;
  bottom: var(--bottom);
  right: var(--right);
  width: calc(100% - (var(--right) + var(--margin)));
  height: calc(100% - (var(--bottom) + var(--margin)));
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  filter: drop-shadow(0px 0.25px 2px rgba(0, 0, 0, 0.039))
    drop-shadow(0px 2.75px 2px rgba(0, 0, 0, 0.19));

  #chat-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    box-sizing: border-box;
    width: 100%;
    height: $headerHeight;
    background: var(--primary_color);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);

    #iara-avatar {
      width: 38px;
      height: 38px;
      //filter: drop-shadow(0px 0.25px 3px rgba(0, 0, 0, 0.039)) drop-shadow(0px 2.75px 9px rgba(0, 0, 0, 0.19));
      flex: none;
      order: 1;
      flex-grow: 0;
      position: relative;
      box-sizing: border-box;
      border-radius: 36.9611px;
      background: url(../assets/img/iara_formal.png) center/cover #ffffff;
      background-position-y: top;
      background-position-x: 52px;
      background-size: 63px;
    }
    p {
      width: 202px;
      height: 20px;

      font-family: "Mark Pro Bold", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;

      display: flex;
      align-items: center;
      letter-spacing: 0.1px;

      color: #ffffff;

      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0 10px;
    }
    .close-chat {
      position: absolute;
      width: 13px;
      height: 24px;
      right: 10px;
      cursor: pointer;
      //bottom: 13px;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;

      /* Dark / System UI */

      color: #ffffff;

      /* Inside Auto Layout */

      flex: none;
      order: 2;
      flex-grow: 0;
      margin: 0 10px;
    }
  }
  #chat-history {
    height: calc(100% - 96px);
    overflow-y: auto;
    margin: 3px 0;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #cccccc;
      -webkit-box-shadow: inset 0 0 6px #cccccc;
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px #555555;
      -webkit-box-shadow: inset 0 0 6px #555555;
      //background-color: #555;
    }
  }

  #chat-text-box {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    padding: 10px 16px;

    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0;
    background: #ffffff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 20%);
    input {
      font-family: "Mark Pro Light", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border: none;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: #333333;
      flex: none;
      order: 1;
      flex-grow: 1;

      &:focus {
        outline: none;
      }
    }
    .send-message {
      width: 40px;
      height: 40px;
      align-items: center;
      text-align: end;
      border: none;
      background: none;
      color: var(--primary_color);

      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.1px;
      cursor: pointer;
      flex: none;
      order: 2;
      flex-grow: 0;

      &:focus {
        outline: none;
      }
    }
  }

  #chat-inventtis {
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - #{$headerHeight});
    border: 0;
  }

  .message-button {
    display: flex;
    order: 2;
    flex-direction: row;
    padding: 4px 8px;
    background: var(--primary_color);
    border-radius: 8px;
    margin: 6px 0;
    font-family: "Mark Pro Bold", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.87);
    text-align: left;
    outline: none;
    box-shadow: none;
    background: #a70000;
    cursor: pointer;
    border: solid 1px #a70000;
    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      background: #a86666;
      border: solid 1px #a86666;

      color: #efefef;
      cursor: pointer;
    }
  }
}
</style>
